import Typography from '../Typography';
import EmailField from '../fields/EmailField';
import Box from '../Box';
import { useMemo } from 'react';
import RadioField from '@/components/fields/RadioField';
import PasswordField from '@/components/fields/PasswordField';

const LoginForm = ({ form, onSubmit, ...properties }) => {
    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <div className={'flex flex-col gap-2'}>
                <Typography>E-mailadres*</Typography>

                <EmailField name={'email'} value={data?.email} error={errors?.email} type={'text'} onChange={handleFormChange} />

                {errors?.email && <Typography color={'error.base'}>{errors.email}</Typography>}
            </div>

            <div className={'flex flex-col gap-2'}>
                <Typography mt={16}>Wachtwoord*</Typography>

                <PasswordField name={'password'} type={'password'} value={data?.password} error={errors?.password} onChange={handleFormChange} />

                {errors?.password && <Typography color={'error.base'}>{errors.password}</Typography>}
            </div>

            <RadioField className={'mt-6'} value={data?.remember === true} title={'Ingelogd blijven?'} onClick={() => form.setData({ ...data, remember: !data.remember })} />

            <input type={'submit'} hidden />
        </Box>
    );
};

export default LoginForm;
